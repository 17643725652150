.projects--ctn {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 20px;
    align-items: center;
    overflow: hidden;
}

.heading {
    color: var(--font-color-heading);
}

.carousal {
    width: 100%;
    height: 600px;
    display: inline-flex;
    overflow: hidden;
    white-space: nowrap;
}

.testing-box {
    display: inline-block;

    min-width: 400px;
    min-height: 200px;
}