.page--ctn {
  width: 100%;
  background-color: var(--main-theme);
  color: var(--font-color);
}

.page {
  animation: loadPage 1s ease-in-out forwards;
}

@keyframes loadPage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}