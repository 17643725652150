.key {
    display: inline-flex;
    position: relative;
    justify-content: center;
    width: fit-content;
    height: 45px;
    border: 1px solid var(--code-color);
    border-radius: 2px;
    background: var(--code-color);
    padding: 0px 5px;
    z-index: 5;
    opacity: 0.7;
    transition: opacity 0.15s ease-in-out;
}

.key-border {
    width: fit-content;
    min-width: 40px;
    height: 40px;
    background-color: var(--main-theme);
    border-radius: 2px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.key-img {
    width: 35px;
    height: 35px;
}

.key-text {
    font-size: var(--font-large);
    margin: 3px 10px;
}

.key:hover {
    cursor: pointer;
    opacity: 1;
}

.img--ctn {
    transition: opacity 0.2s ease-in-out;
}