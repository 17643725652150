.carousalCard {
    display: inline-block;
    position: relative;
    min-width: 800px;
    height: 400px;
    width: 400px;
    margin: auto 100px;
    border-radius: 10px;
    word-wrap: break-word;
}

.card-front {
    transition: filter 0.1s ease-in-out;
}


.card-back {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    box-sizing: border-box;
    display: none;
    border-radius: 10px;
}

.gif-holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.carousalCard:hover .card-back {
    display: block;
    opacity: 1;
}

.carousalCard:hover .card-front {
    filter: blur(3px);
    filter: brightness(15%);
}

.card-back>* {
    margin: 1rem 3rem;
    color: white;
}


.project-heading {
    margin-top: 2rem;
    color: rgba(245, 245, 21, 0.959);
}

.project-summary {
    position: relative;
    display: block;
    width: 400px;
    height: fit-content;
    margin: 0.5rem 3rem;
    white-space: initial;
    color: var(--code-color);
}

a {
    color: white;
}

.project-link {
    display: inline-block;
    margin-top: 0.5rem;
}