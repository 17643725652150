.mobilePage {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mobilePage--text {
    font-size: 2rem;
    margin: 0;
}