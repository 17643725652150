:root {
  --main-theme: rgb(19, 23, 28);
  --code-color: rgb(58, 153, 76);
  --font-color: rgb(86, 162, 232);
  --font-large: 2.5rem;
  --font-heading: 2rem;
  --font-medium: 1.2rem;
  --font-family: 'Share Tech Mono', monospace;
  --font-family-code: 'VT323', monospace;
  --font-color-heading: rgba(248, 81, 66, 0.807);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--main-theme);
  font-family: var(--font-family);
  overflow-x: hidden;
}