.contactMe--ctn {
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.contactMe--text {
    margin: auto;
    font-size: 1rem;
}

.contactMe--text h1 {
    margin: auto;
    width: 80%;
}


.form {
    margin: auto;
    margin-left: 0;
    width: 600px;
    background-color: black;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

input {
    width: 95%;
    height: 25px;
    margin: 15px auto;
    font-size: 1.3rem;
    text-align: left;

    border: none;
    background-color: transparent;
    outline: none;
    padding: 10px;
    color: #ffffff9a;
    border-bottom: 1px solid #ffffff9a;
}

button {
    outline: none;
    border: none;
    margin: auto;
    padding: 5px 20px;
    transition: transform 0.3s ease-in-out;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
}

button:hover {
    transform: scale(1.1);
    cursor: pointer;
}